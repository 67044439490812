import React from 'react';
import { graphql, type PageProps } from 'gatsby';

import StaticPage from '../views/Layout/StaticPage';

interface QueryData {
  terms?: {
    id: string;
    frontmatter: {
      title: string;
    };
    html: string;
  };
}

export const query = graphql`
  query ($language: String!) {
    terms: markdownRemark(frontmatter: { type: { eq: "terms" }, lang: { eq: $language } }) {
      id
      frontmatter {
        title
      }
      html
    }
  }
`;

export default function Terms({ data }: PageProps<QueryData>) {
  return (
    <StaticPage className="my-5" pageview="terms" title={data.terms?.frontmatter.title}>
      <div className="container" dangerouslySetInnerHTML={{ __html: data.terms?.html }} />
    </StaticPage>
  );
}
